import styled from "styled-components"

import { Container } from "../../global"

export const Nav = styled.nav`
  padding: 16px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background: #222;
  transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
`

export const Logo = styled.img`
  max-width: 200px;
  height: auto;

  @media (max-width: ${props => props.theme.screen.xxs}) {
    max-width: 180px;
  }
`

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const NavListWrapper = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
  }
`

export const Brand = styled.div`
  color: ${props => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      color: ${props => props.theme.color.black.regular};
      text-decoration: none;
    }
  }
`
export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;

  .button {
    font-family: ${props => props.theme.font.normal};
    ${props => props.theme.font_size.xsmall};
    color: white;
    background: #2948cf;
    border-radius: 4px;
    padding: 10px 16px;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 12px;
    text-align: center;

    @media (max-width: ${props => props.theme.screen.xxs}) {
      padding: 6px 5px;
    }
  }
`


// Background blur info
// background-color: ${props => props.scrolled && `rgba(245, 245, 250, .8`};
// box-shadow:  ${props =>
//   props.scrolled &&
//   `0 0 0 1px rgba(0,0,50,.02) inset, 0 1px 1px rgba(0,0,50,.05) inset, 0 2px 4px rgba(0,0,50,.04) inset`};
//   backdrop-filter: ${props => props.scrolled && `blur(15px)`};
