const path = require(`path`)

module.exports = {
  siteMetadata: {
    title: `Keto Personal Plan`,
    description: `Get started with the ketogenic diet today, and get a personal diet plan tailored to your specific taste, designed by professional chefs and nutritionalists.`,
    author: `KetoPersonalPlan.com`,
    siteUrl: `https://ketopersonalplan.com`,
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    `gatsby-plugin-styled-components`,
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    `gatsby-transformer-remark`,
    `gatsby-plugin-preact`,

    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `gatsby-starter-default`,
        short_name: `starter`,
        start_url: `/`,
        background_color: `#663399`,
        theme_color: `#663399`,
        display: `minimal-ui`,
        icon: `src/images/ketopersonalplan-icon.png`,
      },
    },

    {
      resolve: `gatsby-plugin-canonical-urls`,
      options: {
        siteUrl: `https://ketopersonalplan.com`,
      },
    },

    {
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        host: false,
        sitemap: `https://ketopersonalplan.com/sitemap.xml`,
        policy: [{ userAgent: '*', allow: '/' }]
      }
    },

    `gatsby-plugin-sitemap`,

    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: "UA-157881316-1",
      },
    },

    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `blog`,
        path: `${__dirname}/src/blog`,
      },
    },

    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `blog`,
        path: `${__dirname}/src/recipes`,
      },
    },

    {
      resolve: `gatsby-source-filesystem`,
      options: {
        path: `${__dirname}/src/images/blog`,
      },
    },

    {
      resolve: `gatsby-source-filesystem`,
      options: {
        path: `${__dirname}/src/images/recipes`,
      },
    },

    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: path.join(__dirname, `src`, `images`),
      },
    },

    {
      resolve: 'gatsby-plugin-mailchimp',
      options: {
          endpoint: 'https://gmail.us4.list-manage.com/subscribe/post?u=cdcc21a3c88d1d8d8c4c5dbb6&amp;id=7e50941b74',
      },
    },
    
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
  ],
}
