import React from 'react';
import styled from "styled-components"

import { Section, Container } from "../components/global"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import Footer from "../components/sections/footer"

const PageTemplate = props => (
  <Layout>
    <SEO
      title={props.title} 
      description={props.description}
    />
    <Navigation />
    <StyledSection>
      <Container>
        {props.children}
      </Container>
    </StyledSection>
    <Footer />
  </Layout>
)

const StyledSection = styled(Section)`
  background-color: #212d3b;

  h1, h2, p, a, ul {
    color: #fff;
  }
`

export default PageTemplate;