import React from "react"

import {
  Nav,
  Brand,
  StyledContainer,
  ActionsContainer,
  Logo,
} from "./style"

import logo from "../../../images/logo.jpg"

const Navigation = props => (
  <Nav {...props}>
    <StyledContainer>
      <Brand>
        <a href="/" aria-label="Keto Personal Plan">
          <Logo src={logo} alt="Keto Personal Plan" />
        </a>
      </Brand>

      <ActionsContainer>
        <a className="button" href="/signup/">Get My Trial</a>
      </ActionsContainer>
    </StyledContainer>
  </Nav>
)

export default Navigation;