import React from "react"
import styled from "styled-components"

import { Container } from "../global"

const Footer = () => (
  <FooterWrapper id="footer">
    <FooterColumnContainer>
    <FooterColumn>
        <span>Social</span>
        <ul>
          <li><a href="https://instagram.com/ketopersonalplan" target="_blank" rel="noopener noreferrer">Instagram</a></li>
          <li><a href="https://twitter.com/keto_personal" target="_blank" rel="noopener noreferrer">Twitter</a></li>
          <li><a href="https://www.facebook.com/KetoPersonalPlan/" target="_blank" rel="noopener noreferrer">Facebook</a></li>
          <li><a href="https://www.pinterest.com/KetoPersonalPlan/" target="_blank" rel="noopener noreferrer">Pinterest</a></li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Resources</span>
        <ul>
          <li><a href="/blog/">Read Our Blog</a></li>
          <li><a href="/recipes/">Easy Keto Recipes</a></li>
          <li><a href="/references/">Scientific Studies</a></li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Legal</span>
        <ul>
          <li><a href="/terms/">Terms and Conditions</a></li>
          <li><a href="/privacy/">Privacy Policy</a></li>
        </ul>
      </FooterColumn>
    </FooterColumnContainer>

    <p>&copy; Keto Personal Plan</p>
  </FooterWrapper>
)

const FooterWrapper = styled.footer`
  background-color: white;
  margin: 80px 0 0;
  padding: 0 0 40px;
  text-align: center;
`

const FooterColumnContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 32px;
  justify-content: start;
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
  }
`
const FooterColumn = styled.div`
  span {
    font-size: 16px;
    font-family: ${props => props.theme.font.bold};
    color: ${props => props.theme.color.primary};
  }

  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${props => props.theme.color.black.regular};

    li {
      margin-bottom: 12px;
      font-family: ${props => props.theme.font.normal};
      font-size: 15px;
    }
    
    a {
      text-decoration: none;
      color: ${props => props.theme.color.primary};
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
      margin-bottom: 40px;
    }
  }
`

export default Footer
